<template>
  <div class="">
    <div class="d-flex">
      <div class="w-25">
        <div class="mb-3">
          Актуальность промокодов
        </div>

        <b-form-group v-slot="{ ariaDescribedby }">
          <b-form-radio
            v-model="filters.isActual"
            :aria-describedby="ariaDescribedby"
            name="some-radios"
            :value="true"
            @input="$emit('search')"
          >
            Только актуальные промокоды
          </b-form-radio>
          <b-form-radio
            v-model="filters.isActual"
            :aria-describedby="ariaDescribedby"
            name="some-radios"
            :value="false"
            @input="$emit('search')"
          >
            Все промокоды
          </b-form-radio>
        </b-form-group>
      </div>

      <div class="w-25 ml-5">
        <div class="mb-3">
          Признак промокода
        </div>

        <b-form-select
          v-model="filters.attribute"
          :options="promoCodeAttributes"
          value-field="id"
          text-field="title"
          size="sm"
          disabled-field="notEnabled"
          class="w-100"
          @input="$emit('search')"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PromocodesSearchFilters',

  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    promoCodeAttributes: [
      {
        id: 0,
        title: 'Мать и дитя',
      },
      {
        id: 1,
        title: 'Доктис',
      },
      {
        id: 2,
        title: 'Депозит',
      },
      {
        id: 3,
        title: 'Не указывать признак',
      },
    ],
  }),

  computed: {
    filters: {
      get() {
        return this.value;
      },
      set(filters) {
        this.$emit('input', filters);
      },
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
